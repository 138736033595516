<template>
  <div class="container">
    <van-nav-bar title="BNS" />

    <div class="content">
      <div>
        <div><strong>BNS: Bitcoin Name System.
        </strong></div>

        <div>
          <p>Decentralized names are secured on the Bitcoin blockchain, registered by Stacks. You can use your .btc name to sign into hundreds of decentralized apps and to send and receive STX. At Gamma, your BNS name is used as your decentralized identity, and is automatically reserved as a profile URL for your NFT collection.</p>

          <p>BNS names also function much like traditional domain names. Compatibility via a web bridge with the DNS system of web 2.0 allows you to use a .btc name for a business or personal site.</p>

          <p></p>
        </div>
      </div>

      <div><strong>Register Your Bitcoin Domain and Step into the Future of Web3! </strong></div>


<div>


  <p>
    Looking for a unique .btc domain? Now’s your chance! Perfect for personal branding, business websites, or even as your crypto wallet address, a .btc domain is your gateway to the decentralized web.</p>
  <p>✅ Secure and Reliable: Powered by blockchain technology, tamper-proof, and fully verifiable.</p>
  <p>✅ Decentralized Ownership: Your domain, your rules—no third-party interference.</p>
  <p>✅ Endless Possibilities: Wallet integration, smart contracts, and more Web3 features.</p>

  </div>





    </div>


    <div>&nbsp;</div>
          <div>&nbsp;</div>
          <div>&nbsp;</div>



    <TabBar />
  </div>
</template>

<script>
import TabBar from '@/components/TabBar.vue'; // 确保路径正确

export default {
  components: {
    TabBar, // 确保 TabBar 组件被注册
  },
}
</script>

<style scoped>
.container {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.content {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 16px;
  overflow-y: auto;
  /* 添加滚动条支持，防止内容溢出 */
}
</style>
