<template>
  <van-page>
    <van-nav-bar title="Tools" />
    <van-cell-group>
      <van-cell
        v-for="(item, index) in features"
        :key="index"
        :title="item.title"
        :icon="item.icon"
        is-link
        @click="navigateTo(item.url)"
      />
    </van-cell-group>
  </van-page>
  <TabBar />
</template>

<script setup>
import { useRouter } from 'vue-router';
import TabBar from '@/components/TabBar.vue';

const router = useRouter();

const features = [
{
    title: 'AES256',
    icon: 'star-o', // 使用 vant 内置的锁图标
    url: '/aes',
  },
  {
    title: 'RSA1024',
    icon: 'star-o', // 使用 vant 内置的锁图标
    url: '/rsa',
  },
  {
    title: 'RSA Signature',
    icon: 'star-o', // 使用 vant 内置的锁图标
    url: '/sign',
  },
  {
    title: 'Hash',
    icon: 'star-o', // 使用 vant 内置的锁图标
    url: '/hash',
  },
  {
    title: 'dhke',
    icon: 'star-o', // 使用 vant 内置的锁图标
    url: '/dhke',
  },
  {
    title: 'shamir',
    icon: 'star-o', // 使用 vant 内置的锁图标
    url: '/shamir',
  },

];

// 导航函数
const navigateTo = (url) => {
  // 检查是否为外部链接
  if (url.startsWith('http')) {
    window.location.href = url;
  } else {
    router.push(url);
  }
};
</script>

<style scoped>
.page-bd {
  padding: 16px;
}
</style>
