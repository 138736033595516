<template>
  <div class="container">
    <van-nav-bar title="Midkey" />

    <div class="content">
      <div>
        <div><strong>Unlocking the Potential of Web3.
        </strong></div>

        <div>
          <p>We empower Web3 innovation with the stability and security of Bitcoin through Stacks. Our
          mission is to enable businesses and developers to build decentralized applications (DApps) and smart contracts
          that leverage Bitcoin’s unmatched security as a foundation for growth and trust.</p>

          <p>With our Stacks-based solutions, you gain access to a powerful ecosystem that combines the programmability of
          smart contracts with Bitcoin’s proven track record. Our platform supports various applications, from
          decentralized finance (DeFi) and social networks to NFTs and asset management, all designed to meet the
          demands of today’s digital landscape.</p>

          <p>Choose a solution that’s secure, scalable, and Bitcoin-native. With Midkey, you’re not just building
          apps—you’re creating a new Web3 experience rooted in the most secure blockchain network. Start your journey
          with us and bring your Web3 vision to life on Bitcoin.</p>
        </div>
      </div>

      <div><strong>Solutions</strong></div>


<div>


  <p>
    Discover a robust range of Web3 solutions built on Stacks, leveraging Bitcoin's security and
    decentralization.
    Our offerings include smart contract development, DApp creation, and token issuance, all designed for
    diverse
    business scenarios—finance, social platforms, NFTs, and beyond. Each solution is tailored to meet the
    specific
    needs of your industry, ensuring you can effectively engage with your audience.</p>
  <p>
    Our development process utilizes modern technologies to enhance your applications' performance and user
    experience. By employing Node.js, we create scalable and efficient server-side solutions that handle
    real-time
    data effortlessly. Combined with Vue 3, we build responsive front-end applications that offer seamless
    interactions and a dynamic user interface, ensuring that your DApps are not only functional but also
    visually
    appealing.</p>
  <p>
    Additionally, our expertise in Clarity enables us to develop secure and predictable smart contracts that are
    fully integrated with the Stacks blockchain. This clarity in programming allows for enhanced security and
    transparency in your transactions, setting a solid foundation for your Web3 projects. Whether you're
    launching
    a new NFT collection or a decentralized finance platform, our team has the skills to deliver robust
    solutions.</p>
  <p>
    Each solution integrates seamlessly with the Bitcoin network, providing unparalleled security and
    interoperability. By tapping into Bitcoin’s stability and transparency, you can build innovative, scalable
    applications and confidently expand your reach in the Web3 landscape. Partner with us to leverage
    cutting-edge
    technology and transform your vision into reality, making a lasting impact in the decentralized economy.</p>

  </div>





    </div>


    <div>&nbsp;</div>
          <div>&nbsp;</div>
          <div>&nbsp;</div>



    <TabBar />
  </div>
</template>

<script>
import TabBar from '@/components/TabBar.vue'; // 确保路径正确

export default {
  components: {
    TabBar, // 确保 TabBar 组件被注册
  },
}
</script>

<style scoped>
.container {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.content {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 16px;
  overflow-y: auto;
  /* 添加滚动条支持，防止内容溢出 */
}
</style>
